.imageContainer {
    display: grid;
    place-items: center;
    padding-top: 10px;
}

.pictureFrame {
    max-width: 500px;
    width: 80%;
    border-radius: 30px;
}