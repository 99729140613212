:root {
    --link-primary: #AEAEAE;
    --theme-primary: #9e1e2b;
}

.specialLink {
    font-size: 35px;
    color: var(--link-primary);
    flex-grow: 5;
    font-weight: 500;
}

.linkFiller{
    flex-grow: 5;    
}

.icon {
    height: 35px;
    color: red;
    fill: red;
}

.iconStack {
    cursor: pointer;
}


