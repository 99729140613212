:root {
    --link-primary: #AEAEAE;
    --theme-primary: #9e1e2b;
    --features: #dedede;
}

.special-link {
    font-size: 40px;
    color: var(--link-primary)
}

.features {
    background-color: var(--features);
}


h1 {
    font-size: 40px;
}
  
h2 {
    font-size: 30px;
}
  