:root {
    --link-primary: #AEAEAE;
    --theme-primary: #9e1e2b;
}

.navigationBar {
    width: 95%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    margin-left: 2.5%;
    margin-right: 2.5%;
}

.navigationOption {
    height: 55px;
    line-height: 55px;
    flex-grow: 1;
    font-weight: 500;
    color: var(--link-primary);
    transition: color .25s ease-in;
    cursor: pointer;
}

.endNavigationOption {
    height: 55px;
    line-height: 55px;
    flex-grow: 2;
    font-weight: 500;
    color: var(--link-primary);
    display: flex;
    justify-content: right;
}

.navigationText {
    height: 55px;
    line-height: 55px;
    margin: 0;
    transition: color .25s ease-in;
    cursor: pointer;
}

.navigationText:hover {
    color: var(--theme-primary)
}

.filler {
    flex-grow: 30;
}

.line {
    width: 95%;
    background-color: #9e1e2b;
    justify-content: "center";
    height: 1px;

    margin-left: 2.5%;
    margin-right: 2.5%;
}