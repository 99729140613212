
:root {
  --link-primary: #AEAEAE;
  --theme-primary: #9e1e2b;
}


.pictureFrame {
  max-width: 400px;
  width: 80%;
  border-radius: 30px;
}

.seeMoreButton{
  height: 40px;
  display: flex;
  line-height: 40px;
  justify-content: center;
  width: 100px;
  background-color: black;
  color: white;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  transition: color .25s ease-in;
}

.seeMoreButton:hover {
  color: var(--theme-primary)
}

.imageContainer {
  display: grid;
  place-items: center;
}


